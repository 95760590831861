<template>
  <v-container fluid fill-height>
    <v-flex d-flex xs12 class="justify-center">
      <v-card class="mb-5 info_auth">
        <v-card-text class="textTitle_wrap">
          Сайт объявлений по работам для строительных и обслуживающих компаний. Помогает получить
          заказы на выполнение работ в ЖКХ в регионах России. Для просмотра объявлений и
          документации, необходимо пройти регистрацию в личном кабинете.
          <a :href="uploadUrl + 'Инструкция_по_регистрации.pdf'" target="blank">
            Инструкция по регистрации.
          </a>
          <span class="font-weight-black">Телефон поддержки +7 812 418-22-36</span>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-layout align-center justify-center>
      <v-flex xs12>
        <v-card max-width="400" class="ma-auto" outlined>
          <v-toolbar flat>
            <v-toolbar-title>Авторизация</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="valid" validation>
              <v-text-field
                class="input_custom"
                name="login"
                label="Логин"
                type="text"
                v-model="login"
                :rules="nameRules"
                required
                outlined
                dense
                clearable
              ></v-text-field>
              <v-text-field
                id="password"
                name="password"
                label="Пароль"
                type="password"
                v-model="password"
                :rules="passwordRules"
                @keyup.enter="onSubmit"
                outlined
                required
                dense
                clearable
              ></v-text-field>
              <v-layout column>
                <v-flex class="reset_btn_wrap">
                  <v-btn color="primary" text x-small to="/reset">Сбросить пароль</v-btn>
                </v-flex>
                <v-flex d-flex class="justify-center">
                  <v-flex class="error_msg body-3 font-weight-medium text-uppercase">
                    {{ errorAuthMsg }}
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-4">
            <v-layout column>
              <v-flex>
                <v-btn class="mb-6" color="primary" @click="onSubmit" :disabled="!valid" block>
                  Войти
                </v-btn>
              </v-flex>
              <v-flex>
                <v-btn color="primary" outlined block to="registration">Регистрация</v-btn>
              </v-flex>
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="text-center">
      <v-bottom-sheet v-model="notifyCookie" hide-overlay>
        <v-sheet class="text-center pt-3" height="150px">
          <v-flex d-flex class="justify-end mr-5">
            <v-btn color="primary" icon @click="notifyCookie = !notifyCookie">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
          <v-flex class="px-5">
            <div class="font-weight-light mb-3">
              С целью оптимальной работы веб-сайта и его постоянного обновления АО "Трест № 37
              Ленинградспецстрой" используют Cookies (куки-файлы), для сбора и статистического
              анализа данных, связанных с посещением нашего ресурса. Продолжая использовать наш
              веб-сайт, Вы соглашаетесь на использование куки-файлов и указанного сервиса для
              отслеживания предпочтений посетителей сайта и соответствующей оптимизации его работы.
              Куки-файлы - это небольшие файлы, которые сохраняются на жестком диске Вашего
              устройства. Они облегчают навигацию и делают посещение сайта более удобным.
            </div>
          </v-flex>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      notifyCookie: false,
      uploadUrl: process.env.VUE_APP_UPLOADS_URL,
      errorAuthMsg: '',
      login: '',
      password: '',
      minPassLen: 1,
      valid: false,
      nameRules: [v => !!v || 'Необходимо ввести логин'],
      passwordRules: [
        v => !!v || 'Необходимо ввести пароль',
        v =>
          (v && v.length >= this.minPassLen) ||
          `Пароль должен быть не менее ${this.minPassLen} символов`
      ]
    };
  },
  methods: {
    onSubmit() {
      if (this.$refs.form.validate()) {
        return new Promise(resolve => {
          const action = 'auth';
          const dataParams = {
            login: this.login,
            password: this.password
          };
          this.$store
            .dispatch('login', { action, dataParams })
            .then(resp => {
              if (resp.result == 'unconfirmed') {
                this.errorAuthMsg = 'Аккаунт не существует или еще не подтвержден.';
              } else if (resp.result == 'incorrect') {
                this.errorAuthMsg = 'Неверное имя пользователя или пароль';
              } else if (resp.result == 'success') {
                const id = resp.id;
                const role = resp.role;
                const token = resp.token;
                const org = resp.org;
                const login = resp.login;
                const insurance = resp.insurance;
                const limit = resp.limit;
                const approveData = resp.approvedata;
                const approveMail = resp.approvemail;
                const requestRevoke = resp.requestrevoke ? true : false;
                const restrict = resp.restrict;
                this.$store.commit('setId', { id });
                this.$store.commit('setRole', { role });
                this.$store.commit('setToken', { token });
                this.$store.commit('setOrg', { org });
                this.$store.commit('setLogin', { login });
                this.$store.commit('setInsurance', { insurance });
                this.$store.commit('setLimit', { limit });
                this.$store.commit('setApproveData', { approveData });
                this.$store.commit('setApproveMail', { approveMail });
                this.$store.commit('setRequestRevoke', { requestRevoke });
                this.$store.commit('setRestrict', { restrict });

                if (role != 'admin' && (!approveData || !approveMail)) {
                  this.$router.push('/approve');
                } else if (role == 'admin') {
                  this.$router.push('/admin/newtenders');
                } else if (role == 'contractor') {
                  this.$router.push('/contractor/newtenders');
                } else if (role == 'organizer') {
                  this.$router.push('/organizer/newtenders');
                } else if (role == 'insurance') {
                  this.$router.push('/insurance/info');
                }
              }
              resolve(resp);
            })
            .catch(err => {
              console.log(err);
            });
        });
      }
    }
  },
  mounted() {
    if (!this.$cookies.get('notifyCookie')) {
      this.notifyCookie = true;
      this.$cookies.set('notifyCookie', true, Infinity);
    }
  }
};
</script>
<style scoped>
.textTitle_wrap {
  text-align: center;
}

.info_auth {
  max-width: 1200px;
}

.reset_btn_wrap {
  text-align: end;
}

.input_custom > input {
  background-color: rgb(255, 255, 255) !important;
}

.error_msg {
  height: 22px;
  text-align: center;
  margin-top: 10px;
  color: red !important;
}
</style>
